import * as React from "react"
import styled from "styled-components";
import fonts from "../../../styles/fonts";
import colors from "../../../styles/colors";
import BlocksContent from "../BlocksContent/BlocksContent";

const StyledPageContent = styled.div`
  width: 100%;
  position:relative;
  
  p {
    font-size: 17px;
    line-height: 27px;
    margin-bottom: 40px;
  }
  
  h2 {
    ${fonts.gloryBold};
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 20px;
  }
  
  h3 {
    ${fonts.gloryBold};
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
  }
  
  strong, b {
    ${fonts.gloryBold};
  }
  
  ul {
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 28px;
  
    li {
      margin-bottom: 0;
      
      p {
        margin-bottom: 12px;
      } 
    }
  }
  
  a {
    color: ${colors.white};
    text-decoration: none;
    padding-bottom: 2px solid ${colors.white};
    border-bottom: 1px solid ${colors.white};
    cursor: pointer;
  
    &:hover {
      color: ${colors.blue};
      border-color: ${colors.blue};
    }
  }
  
  img {
    max-width: 100%;
    margin-bottom: 40px;
  }
  
  i {
    font-style: italic;
  }
`;

const PageContentBlock = ({page}) => {
  return (
    <StyledPageContent>
      <BlocksContent page={page} />
    </StyledPageContent>
  )
}

export default PageContentBlock;
