import React from "react";
import Layout from "../components/Layout/Layout";
import {graphql} from "gatsby";
import HeroBlock from "../components/Core/HeroBlock";
import Seo from "../components/Core/Seo";
import {renderRichText} from "gatsby-source-contentful/rich-text"
import IntroBlock from "../components/Core/IntroBlock";
import PageContent from "../components/Core/PageContent/PageContent";
import styled from "styled-components";
import breakpoints from "../styles/breakpoints";

const StyledContentContainer = styled.div`
  width: 100%;
  max-width: ${(props) => props.small ? `680px` : `1040px`};
  margin: auto;
  padding: 0 40px 100px 40px;

  @media (min-width: ${breakpoints.lg}) {
    padding: 40px 40px 100px 40px;
  }
`;

const Page = ({location, data}) => {
  const {page} = data
  const {title, metaTitle, metaDescription, hero, intro, slug} = page

  let sharingImage = false;

  if (hero && hero.file) {
    sharingImage = hero?.file?.url;
  }

  let smallContainerPages = [
    "terms",
    "about",
    "game-rules"
  ];

  const isSmallContainer = smallContainerPages.includes(slug);

  return (
    <Layout>
      <Seo title={metaTitle}
           description={metaDescription}
           image={sharingImage}
           path={location.pathname}/>
      <HeroBlock title={title} image={(hero && hero.file) ? hero.file.url : null} />
      <StyledContentContainer small={isSmallContainer}>
        {intro &&
        <IntroBlock>
          {renderRichText(intro)}
        </IntroBlock>}
        <PageContent page={page} />
      </StyledContentContainer>
    </Layout>
  )
}

export default Page;

export const pageQuery = graphql`
  query PageQuery($slug: String!) {
    page: contentfulPage(slug: {eq: $slug}) {
      id
      slug
      title
      metaTitle
      metaDescription
      hero {
        file {
          url
        }
      }
      intro {
        raw
      }
      references {
        __typename
        ... on Node {
          ... on ContentfulContentBlock {
            content {
              raw
            }
          }
          ... on ContentfulTextImageBlock {
            image {
              gatsbyImageData(width: 1200)
              file {
                url
              }
            }
            content {
              raw
            }
            imagePosition
          }
          ... on ContentfulImageBlock {
            image {
              gatsbyImageData(width: 1200)
              file {
                url
              }
            }
            includeFrame
          }
        }
      }
    }
  }
`
